import React, { Suspense } from 'react';
import { createBrowserRouter, RouterProvider, ScrollRestoration, Outlet,useLocation  } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { GlobalContext } from './globalContext';
import  CatalogProvider  from './CatalogProvider';
import download from 'downloadjs';
import HomePage from './Views/HomePage/';
import DataCatalogueMain from './Views/DataCatalogueMain/';
import MacroIndicatorsMain from './Views/MacroIndicatorsMain/';
import DataCatalogue from './Views/DataCatalogue';
import EmbedPage from './Views/EmbedPage';
import ChartEmbedPage from './Views/EmbedPage/ChartEmbed';
import MacroPage from './Views/MacroPage';
// import NssTransition from './Views/NssTransition';
import EconomicCensus from './Views/EconomicCensus';
import { Snackbar } from '@mui/material';
import './main.css'
const HomeOrMacroPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const product = query.get('product');

  if (location.pathname === '/macroindicators' && product) {
    return <MacroPage />;
  } else {
    return <HomePage />;
  }
};
const router = createBrowserRouter([
  {
    path: "/", Component: Root, children: [
      { index: true, Component: HomeOrMacroPage },
      { path: "macroindicators", Component: HomeOrMacroPage },
      {
        path: "catalogue-main", children: [
          { index: true, Component: DataCatalogueMain },
          {
            path: "catalogue", children: [
              { index: true, Component: DataCatalogue },
              { path: "chartview/:itemid", Component: ChartEmbedPage },
              { path: "tableview/:itemid", Component: EmbedPage }
            ]
          },
        ]
      },
      {
        path: "macroindicators-main", children: [
          { index: true, Component: MacroIndicatorsMain },
          {
            path: "macroindicators", Component: MacroPage
          }
        ]
      },
      // {
      //   path: "nss-surveys", children: [
      //     { index: true, Component: NssTransition },
      //     {
      //       path: "macroindicators", Component: MacroPage
      //     }
      //   ]
      // },
      {
        path: "economic-census", children: [
          { index: true, Component: EconomicCensus },
          {
            path: "macroindicators", Component: MacroPage
          }
        ]
      }
    ]
  }
]);

function Root() {
  let getKey = React.useCallback(
    (location, matches) => {
      return location.pathname;
    }, []);

  return (
    <React.Fragment>
      <Outlet />
      <ScrollRestoration getKey={getKey} />
    </React.Fragment>
  );
}

const App = () => {
  const [downloadPreparing, setDownloadPreparing] = React.useState(false);

  // onClick handler to download XLSX-format documents
  const handleRecordDownload = (id, downloadFunction, fetchParams) => async (_event) => {
    setDownloadPreparing(true);
    downloadFunction({ ...fetchParams }).then(res => {
      download(res.data, `${id}.xlsx`, res.headers['content-type']);
    }).then(_res => {
      setDownloadPreparing(false);
    });

  };

  return (
          <CatalogProvider>
            <GlobalContext.Provider value={{ downloadPreparing, setDownloadPreparing, handleRecordDownload }}>
                <Suspense>
                    <RouterProvider router={router} />

                </Suspense>
            <Snackbar open={downloadPreparing} message="Download in progress..." anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} />
          </GlobalContext.Provider>
        </CatalogProvider>
 );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
